exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-championships-js": () => import("./../../../src/pages/championships.js" /* webpackChunkName: "component---src-pages-championships-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indoor-2020-js": () => import("./../../../src/pages/indoor2020.js" /* webpackChunkName: "component---src-pages-indoor-2020-js" */),
  "component---src-pages-indoor-2022-js": () => import("./../../../src/pages/indoor2022.js" /* webpackChunkName: "component---src-pages-indoor-2022-js" */),
  "component---src-pages-members-js": () => import("./../../../src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-pages-outdoor-2021-js": () => import("./../../../src/pages/outdoor2021.js" /* webpackChunkName: "component---src-pages-outdoor-2021-js" */),
  "component---src-pages-outdoor-2022-js": () => import("./../../../src/pages/outdoor2022.js" /* webpackChunkName: "component---src-pages-outdoor-2022-js" */),
  "component---src-pages-records-js": () => import("./../../../src/pages/records.js" /* webpackChunkName: "component---src-pages-records-js" */)
}

